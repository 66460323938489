import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import swal from "sweetalert";
import { useEffect } from "react";
import { Loader } from "rsuite";
import { Modal } from "react-bootstrap";
import { AppContext } from "../../context/user/AppContext";

export default function EditTransporterApproval() {
  const navigate = useNavigate();
  const { customerData } = useContext(AppContext);
  const [transporterPendingList, setTransporterPendingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comment, setComment] = useState("");
  const [action, setAction] = useState(false);

  const getTransporterList = () => {
    var uri = ApiConfig.UPDATED_TRANSPORTER_LIST;
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setTransporterPendingList(res.json.transporter_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const transportApprove = (id) => {
    simplePostCall(
      ApiConfig.APPROVE_USER,
      JSON.stringify({
        ...customerData,
        pending_user_id: id,
        comments: comment,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        getTransporterList();
        navigate("/TransporterLists/");
        swal(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const transportReject = (id) => {
    simplePostCall(
      ApiConfig.REJECT_USER,
      JSON.stringify({
        ...customerData,
        pending_user_id: id,
        comments: comment,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        getTransporterList();
        swal(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTransporterList();
  }, []);
  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  {loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <table className="dataList" style={{ minWidth: "900px" }}>
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Company Type</label>
                          </th>
                          <th>
                            <label>Company Name</label>
                          </th>
                          <th>
                            <label>Contact Person</label>
                          </th>
                          <th>
                            <label>Fleet Size</label>
                          </th>
                          <th>
                            <label>Country</label>
                          </th>
                          <th>
                            <label>Status</label>
                          </th>
                          <th
                            className="text-end"
                            style={{ paddingRight: "60px" }}
                          >
                            <label>Action</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transporterPendingList &&
                          transporterPendingList.map(
                            (transporterList, index) => {
                              return (
                                <tr className="Rowbgcolor">
                                  <td>
                                    <label>{index + 1}</label>
                                  </td>
                                  <td>
                                    <label>
                                      {transporterList.company_type}
                                    </label>
                                  </td>
                                  <td>
                                    <Link
                                      className="text-decoration-none"
                                      to={
                                        "/TransporterDetails/" +
                                        transporterList.user_id
                                      }
                                      state={transporterList}
                                    >
                                      <label
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "capitalize",
                                          color: "#385f84",
                                          textDecoration: "underline",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {transporterList.company_name}
                                      </label>
                                    </Link>
                                  </td>
                                  <td>
                                    <label>
                                      {transporterList.contact_person_phone}
                                    </label>
                                  </td>
                                  <td>
                                    <label>
                                      {transporterList.user_fleet_size}
                                    </label>
                                  </td>
                                  <td>
                                    <label>
                                      {transporterList.user_country}
                                    </label>
                                  </td>
                                  <td>
                                    <label>{transporterList.user_status}</label>
                                  </td>

                                  <td className="text-end">
                                    <label>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          handleShow(setAction(false));
                                        }}
                                        className="regiBTN"
                                        id="accept"
                                      >
                                        Accept
                                        {/* <img src={cross_green} alt="" /> */}
                                      </Link>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          handleShow(setAction(true));
                                        }}
                                        className="regiBTN"
                                        id="reject"
                                      >
                                        {/* <img src={tick_green} alt="" /> */}
                                        Reject
                                      </Link>
                                    </label>
                                  </td>
                                  <Modal
                                    show={show}
                                    onHide={handleClose}
                                    animation={true}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className="modal-content-box vehicle-requests-main-modal "
                                  >
                                    <Modal.Header
                                      onClick={() => {
                                        setModal(!modal);
                                      }}
                                      closeButton
                                    ></Modal.Header>

                                    <div>
                                      <div class="mb-3">
                                        <label for="comment" class="form-label">
                                          Comment
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="comment"
                                          onChange={(e) => {
                                            setComment(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="btn-popup-modal text-center mt-4">
                                      {!action ? (
                                        <button
                                          className="popup-approve-btn m-1 "
                                          onHide={handleClose}
                                          onClick={() =>
                                            transportApprove(
                                              transporterList.user_id
                                            )
                                          }
                                          disabled={
                                            comment === "" ? true : false
                                          }
                                        >
                                          Accept
                                        </button>
                                      ) : (
                                        <button
                                          className="popup-reject-btn "
                                          onHide={handleClose}
                                          onClick={() =>
                                            transportReject(
                                              transporterList.user_id
                                            )
                                          }
                                          disabled={
                                            comment === "" ? true : false
                                          }
                                        >
                                          Reject
                                        </button>
                                      )}
                                    </div>
                                  </Modal>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
