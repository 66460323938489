import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import "../../assets/css/Transportvehicle.scss";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_excel.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import ChangeDriver from "../Transporter/ChangeDriver";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { PostCallWithErrorResponse, postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import WhiteTolltip from "../../sharedComponent/WhiteTolltip";
import * as XLSX from "xlsx";

const VehicleList = () => {
  const [loading, seLoading] = useState(false);
  const [vehicleList, setvehicleList] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [selected, setSelected] = useState("All");
  const { mainClass, customerData, setApiCalling } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    getVehicleList();
  }, []);
  useEffect(() => {
    $(".dataList").DataTable().destroy();
    if (selected === "All" || selected === "select") {
      setvehicleList(originalData);
    } else {
      setvehicleList(
        originalData.filter((data) => {
          // if (selected === "inactive") return data.vehicle_status === "blocked";
          // else return data.vehicle_status === selected;
          if (selected === "active") return (data.vehicle_status === "active");
          else if (selected === "Busy") return data.vehicle_status === "vehicle_added" || data.vehicle_status === "vehicle_match_partially_confirmed";
          else if (selected === "inactive") return (data.vehicle_status !== "vehicle_added" && data.vehicle_status !== "vehicle_match_partially_confirmed" && data.vehicle_status !== "active");

        })
      );
    }
  }, [selected]);
  useEffect(() => {
    return () => {
      setvehicleList([]);
    };
  }, []);
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Company Name",
        "Driver Name",
        "Vehicle Type",
        "Model",
        "Plate No.",
        "Status",
      ],
    ];
    vehicleList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.owner_name,
        elt.driver_name,
        elt.vehicle_type,
        elt.vehicle_model_no,
        elt.plate_number,
        elt.vehicle_status,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Vehicles_list.xlsx");
  };
  const blockvehicle = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getVehicleList();
      }
    });
  };
  const unBlockvehicle = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getVehicleList();
      }
    });
  };

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  const changeDriver = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.CHANGE_DRIVER,
      JSON.stringify({
        ...customerData,
        vehicle_id: currentVehicle.vehicle_id,
        driver_id: id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        $(".dataList").DataTable().destroy();
        getVehicleList();
        swal(res.json.message);
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
      });
  };
  const deleteVehicle = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getVehicleList();
      } else{
        swal(res.json.message);

      }
    });
  };

  const getVehicleList = () => {
    seLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => { 
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        seLoading(false);
        if (res.json.result) {
          setvehicleList(res.json.vehicle_list);
          setOriginalData(res.json.vehicle_list);
          setCurrentVehicle(res.json.vehicle_list[0]);
        }
      })
      .catch((err) => {
        seLoading(false);
        console.log(err);
      });
  };
  

  const approve = (id, status) => {
    setApiCalling(true);

    PostCallWithErrorResponse(ApiConfig.VEHICLE_ADD_APPROVAL, {
      ...customerData,
      vehicle_id: id,
      status: status === "rejected" ? "active" : "",
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getVehicleList();
          // navigate("/VehicleList/");
          swal(res.json.message);
          setApiCalling(false);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className={`vehicle-list ${mainClass}`}>
        <div>
          <div className="d-flex  ">
            <div className="input-group   justify-content-end ">
              <div className="icon-group   mt-2 d-flex mr-5 ">
                <ul className="d-flex list-unstyled   ">
                  <li>
                    {" "}
                    <div className="form-outline searchBox  m-0 ">
                      <label htmlFor="select" className="mr-4">
                        Filter
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setSelected(e.target.value)}
                      >
                        <option value={"All"}>All</option>
                         {/* <option value={"available"}>Available</option> */}
                        <option value={"Busy"}>Busy</option> 
                        <option value={"active"}>Active</option>
                        <option value={"inactive"}>Inactive</option>
                      </select>
                    </div>
                  </li>
                  {/* <li className="nav-item icons p-2 me-3 " data-tip="Export">
                    <Link to="#">
                      <img
                        src={ic_export}
                        className="add mb-2"
                        alt="send-load-icon"
                      />
                    </Link>
                  </li> */}
                  <li
                    className="nav-item icons p-2 me-3"
                    data-tip="Export to Excel"
                  >
                    <Link to="#" onClick={exportToexcel}>
                      <img
                        // onClick={() => Setshow(!show)}
                        src={ic_import}
                        className="add"
                        alt="send-load-icon"
                      />
                    </Link>
                    {/* <div className="icon-tooltip">Import Via Excel</div> */}
                  </li>
                  <li className="nav-item icons p-2" data-tip="Add Vehicle">
                    <Link to="/AddVehicles">
                      <img src={ic_add} className="add" alt="send-load-icon" />
                    </Link>
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className=""
            style={{ width: "100%", overflow: "auto", padding: "30px 0" }}
          >
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <table className="dataList " style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    {/* <th>
                      <label>Owner Name</label>
                    </th> */}
                    <th>
                      <label>Company Name</label>
                    </th>
                    {/* <th>
                      <label>Driver Name</label>
                    </th> */}
                    <th>
                      <label>Vehicle Type</label>
                    </th>
                    <th>
                      <label>Model</label>
                    </th>
                    <th>
                      <label>Plate No.</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleList.map((vehicle, index) => {
                    return (
                      <tr className="Rowbgcolor p-2" key={"vehicle" + index}>
                        <td>{index + 1}</td>
                        {/* <td >{vehicle.owner_name} </td> */}
                        <td>{vehicle.owner_name}</td>
                        {/* <td >{vehicle.driver_name}</td> */}
                        <td>
                          {vehicle.vehicle_type}
                          <span className="etc">...</span>{" "}
                        </td>
                        <td>{vehicle.vehicle_model_no}</td>
                        <td>{vehicle.plate_number}</td>
                        <td>{vehicle.vehicle_status}</td>
                        <td>
                          <div className="option-dropdown dropdown p-2">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => setCurrentVehicle(vehicle)}
                            >
                              <img src={ic_options} alt="" />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    navigate(
                                      "/AddVehicles/" + vehicle.vehicle_id
                                    )
                                  }
                                >
                                  Edit{" "}
                                  <img
                                    src={dropdown_arrow_blue}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </li>
                              {vehicle.vehicle_status === 'rejected' ? <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    approve(vehicle.vehicle_id, "rejected");
                                    navigate(
                                      "/ApprovalHeader"
                                    )}
                                  }
                                >
                                  Approve{" "}
                                  <img
                                    src={dropdown_arrow_blue}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </li>: <></>}
                              <li>
                                <Link
                                  to={
                                    "/VehicleDriverDetailsHeader/" +
                                    vehicle.vehicle_id +
                                    "/false"
                                  }
                                  state={{
                                    id: vehicle.vehicle_id,
                                    transporter_id: vehicle.owner_id,
                                  }}
                                  className="text-decoration-none"
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                </Link>
                              </li>
                              {/* <li>
                                <Link
                                  to="/ChangeDriver"
                                  className="text-decoration-none"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasDriverAccount"
                                  aria-controls="offcanvasRightAccount"
                                >
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                  >
                                    Assign Driver
                                  </button>
                                </Link>
                              </li> */}
                               {/* <li>
                                <Link
                                  to="/"
                                  className="text-decoration-none"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasDriverAccount"
                                  aria-controls="offcanvasRightAccount"
                                >
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                  >
                                    Assign Driver
                                  </button>
                                </Link>
                              </li>  */}
                              {vehicle.vehicle_status === "blocked" ? (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      unBlockvehicle(vehicle.vehicle_id);
                                    }}
                                  >
                                    Unblock
                                  </button>
                                </li>
                              ) : (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      blockvehicle(vehicle.vehicle_id);
                                    }}
                                  >
                                    Block
                                  </button>
                                </li>
                              )}
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    deleteVehicle(vehicle.vehicle_id)
                                  }
                                  className="text-decoration-none"
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {/* // <div className="">
            //   <div className="row mt-5">
            //     <div className="text-center mt-4">
            //       <Link to="/AddVehicles">
            //         <img src={vehicle_empty_state} className="ps-4" alt="" />
            //       </Link>
            //     </div>
            //   </div>
            // </div> */}

        <ChangeDriver
          changeDriver={changeDriver}
          transporter_id={currentVehicle && currentVehicle.owner_id}
        />

        {/* <div className="Vehiclelistpage">
            <a href="#" className="R-arow">
              {" "}
              <img className="Larow" src={ic_prev} alt="" />{" "}
              <img src={ic_prev_active} alt="" />{" "}
            </a>
            <a href="#">1</a>
            <a href="#">2 </a>
            <a href="#">3</a>
            <a href="#">4</a>
            <a href="#" className="L-arow">
              <img className="Larow" src={ic_next} alt="" />{" "}
              <img src={ic_next_active} alt="" />{" "}
            </a>
          </div> */}
      </div>
    </React.Fragment>
  );
};

export default VehicleList;
