import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import tick_green from "../../assets/images/tick_green.svg";

import ic_remove from "../../assets/images/ic_remove1.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import ic_dropdown_arrow from "../../assets/images/dropdown_arrow_blue.svg";

import swal from "sweetalert";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
const VehicleRequest = () => {
  const [searchText, setSearchText] = useState("");
  const [orginalList, setOrginalList] = useState([]);
  const { mainClass, customerData, orderType, setOrderType } =
    useContext(AppContext);
  const [vehicleRequestList, setVehicleRequestList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [orderTypeModal, setOrderTypeModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentVehicleRequest, setCurrentVehicleRequest] = useState({});
  useEffect(() => {
    getVehicleRequestList();
  }, []);
  useEffect(() => {
    let list = orginalList.filter((data) =>
      data.load_reference_no.includes(searchText)
    );

    setVehicleRequestList(list);
  }, [searchText]);
  const getVehicleRequestList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_REQUEST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setVehicleRequestList(res.json.load_list);
          setOrginalList(res.json.load_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const reject = (curVehReq) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.REJECT_VEHICLE_REQUEST,
      JSON.stringify({
        ...customerData,
        load_id: curVehReq?.trip_id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        swal(res.json.message);
        getVehicleRequestList();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const gotoNext = (e) => {
    e.preventDefault();
    localStorage.setItem(
      "currentVehicleRequest",
      JSON.stringify(currentVehicleRequest)
    );
    handleClose();
    //setOrderType("direct");
    navigate("/SelectTransporter");
  };

  return (
    <React.Fragment>
      <div
        className={`section-main  vehicle-requests-main ${mainClass} `}
        id="cxMainB"
        style={{ transition: "all 0.5s" }}
      >
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="ms-3 vehicleRequest">
            <div className="searchtab ">
              <input
                type={"text"}
                className="form-control"
                placeholder="Search By Reference No"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className="offerLoadsMainLists">
              <div className="mainLoadTable" style={{ overflow: "auto" }}>
                <table className="dataList" style={{ minWidth: "1000px" }}>
                  <thead>
                    <tr>
                      <th>
                        <label>S.No</label>
                      </th>
                      <th>
                        <label>Reference No.</label>
                      </th>
                      <th>
                        <label>From-To</label>
                      </th>
                      <th>
                        <label>Offered By</label>
                      </th>
                      <th>
                        <label>Cargo Type</label>
                      </th>
                      <th>
                        <label>Container Type Type</label>
                      </th>
                      <th>
                        <label>Remaining Qty.</label>
                      </th>
                      <th>
                        <label>Est.Delivery.Dt</label>
                      </th>
                      <th>
                        <label>Is Delivery?</label>
                      </th>
                      <th>
                        <label>Trip Status</label>
                      </th>
                      <th className="" style={{ paddingLeft: "35px" }}>
                        <label>Action</label>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleRequestList.length ? (
                      vehicleRequestList.map((vehicleRequest, index) => {
                        return (
                          <tr className="Rowbgcolor">
                            <td>
                              <label>{index + 1}</label>
                            </td>
                            <td>
                              <label>
                                <img
                                  src={
                                    vehicleRequest.trip_image
                                      ? ApiConfig.BASE_URL_FOR_IMAGES +
                                        vehicleRequest.trip_image
                                      : ic_trucks
                                  }
                                  alt=""
                                  className="directLoad-img card-rsponsive-img vehicle-request-img"
                                  style={{
                                    height: "22px",
                                    width: "22px",
                                    marginRight: "7px",
                                  }}
                                />
                                <Link
                                  className="green-link-btn"
                                  to={"/ViewDetails/" + vehicleRequest.trip_id}
                                >
                                  {vehicleRequest.load_reference_no}
                                </Link>
                              </label>
                            </td>
                            <td>
                              <label>
                                {vehicleRequest.trip_start_city +
                                  " " +
                                  vehicleRequest.trip_start_country +
                                  `-` +
                                  vehicleRequest.trip_end_city +
                                  " " +
                                  vehicleRequest.trip_end_country}
                              </label>
                            </td>
                            <td>
                              <label>{vehicleRequest.offered_by}</label>
                            </td>
                            <td>
                              <label>{vehicleRequest.cargo_type === "Other (Specify)" ? vehicleRequest.trip_other_cargo_type : vehicleRequest.cargo_type}</label>
                            </td>
                            <td>
                              {vehicleRequest.cargo_type == "Container" ? (
                                <label className="data m-0">
                                  {vehicleRequest.container_type}
                                </label>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <>
                                {(vehicleRequest.trip_status === "reassign" ||
                                // vehicleRequest.trip_status === 'match_made_partially' || 
                                // vehicleRequest.trip_status === 'partially_confirmed' ||
                                // vehicleRequest.trip_status === 'partially_initiated' ||
                                // vehicleRequest.trip_status === 'partially_completed' ||
                                vehicleRequest.vehicle_request_status === 'reconfirm') ? (
                                  vehicleRequest.cargo_type == "Container" ? (
                                    <label className="data m-0">{vehicleRequest.pending_quantity === "" ? vehicleRequest.quantity : vehicleRequest.pending_quantity} Quintals</label>
                                  ):vehicleRequest.cargo_type == "Vehicle" ? (
                                    <label className="data m-0">{vehicleRequest.pending_quantity} Vehicles</label>
                                  ):(<label className="data m-0">{vehicleRequest.pending_quantity} Quintals</label>)
                                ) : (
                                  vehicleRequest.cargo_type == "Container" ? (
                                    <label className="data m-0">{vehicleRequest.pending_quantity === "" ? vehicleRequest.quantity : vehicleRequest.pending_quantity} Quintals</label>
                                  ):vehicleRequest.cargo_type == "Vehicle" ? (
                                    <label className="data m-0">{vehicleRequest.quantity} Vehicles</label>
                                  ):(<label className="data m-0">{vehicleRequest.quantity} Quintals</label>)
                                )}
                              </>
                            </td>
                            <td>
                              <label>
                                {vehicleRequest.trip_delivery_date}
                              </label>
                            </td>
                            <td>
                              <label>
                                {vehicleRequest.trip_is_delivery === true ? "Yes" : "No"}
                              </label>
                            </td>
                            <td>
                              <label>{(vehicleRequest.vehicle_request_status==='reconfirm') ? 'reassign' : vehicleRequest.trip_status}</label>
                            </td>
                            
                            <td>
                             {vehicleRequest.trip_status === "rejected" ? <></> :<label htmlFor="">
                                <div className="btn d-flex justify-content-between ">
                                  <button
                                    type="button"
                                    className=" btn-remove text-center "
                                    onClick={() => {
                                      setCurrentVehicleRequest(vehicleRequest);
                                      reject(vehicleRequest);
                                    }}
                                  >
                                    <img className="" src={ic_remove} alt="" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-remove ms-3 "
                                    onClick={(e) => {
                                      
                                      setCurrentVehicleRequest(vehicleRequest);
                                      handleShow();
                                    }}
                                  >
                                    <img src={tick_green} alt="" />
                                  </button>
                                </div>
                              </label>}
                            </td>
                            <td>
                              {/* <label>
                                <div className="option-dropdown dropdown ">
                                  <Link
                                    to="#"
                                    href=""
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={ic_options} alt="" />
                                  </Link>
                                  <ul className="dropdown-menu ">
                                    <li>
                                      <Link
                                        to={
                                          "/ContractShipper/" +
                                          vehicleRequest.shipper_id
                                        }
                                        className="text-decoration-none"
                                      >
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                        >
                                          View Details
                                          <img
                                            src={ic_dropdown_arrow}
                                            className="arrowvehicle"
                                            alt=""
                                          />
                                        </button>
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item mt-2"
                                        type="button"
                                        to="#offcanvasDriverAccount"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasFrightStatus"
                                        aria-controls="offcanvasRightAccount"
                                      >
                                        View Transporter Response
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </label> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="text-danger"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No Load Available
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-main-modal "
      >
        <Modal.Header
          onClick={() => {
            setOrderTypeModal(!orderTypeModal);
          }}
          closeButton
        ></Modal.Header>

        <div>
          <p className="text-center popup-heading mt-4">
            Please Select Order Type?
          </p>
        </div>
        <div className="btn-popup-modal text-center mt-4">
        {currentVehicleRequest && (currentVehicleRequest.trip_category === 'online'
          || currentVehicleRequest.trip_category === '')
          && <button
            className="popup-approve-btn m-1 "
            onClick={() => {
              handleClose();
              setShow(!show);
              setOrderType("online");
              navigate("/CreateAuction");
              localStorage.setItem(
                "currentVehicleRequest",
                JSON.stringify(currentVehicleRequest)
              );
            }}
            onHide={handleClose}
          >
            Online Auction
          </button>}
          {currentVehicleRequest && (currentVehicleRequest.trip_category === 'direct'
          || currentVehicleRequest.trip_category === '')
           && <button className="popup-approve-btn m-1 ">
            <Link
              to="#"
              className="text-decoration-none"
              style={{ color: "#506c87" }}
              onClick={(e)=>{gotoNext(e);
                setOrderType("direct");}}
            >
              Direct Order
            </Link>
          </button>}
          <button
            className="popup-reject-btn m-1 "
            onClick={() => {
              handleClose();
              reject(currentVehicleRequest);
            }}
            onHide={handleClose}
          >
            Reject{" "}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default VehicleRequest;
