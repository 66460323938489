import React, { useContext, useEffect, useState } from "react";
import ic_edit from "../../assets/images/ic_edit (2).svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import {
  postWithAuthCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

const DeliveryApproval = () => {
  const route = useLocation().pathname;
  const navigate = useNavigate();
  const { customerData } = useContext(AppContext);
  const [transporterPendingList, setTransporterPendingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [uploadContract, setUploadContract] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTransporterList = () => {
    var uri = ApiConfig.DELIVERY_PENDING_LIST;
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setTransporterPendingList(res.json.transporter_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const transportApprove = (id) => {
    PostCallWithErrorResponse(ApiConfig.APPROVE_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getTransporterList();
          navigate("/DeliveryLists/");
          swal(res.json.message);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const transportReject = (id) => {
    PostCallWithErrorResponse(ApiConfig.REJECT_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getTransporterList();
          swal(res.message);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deliveryAccept = (id) => {
    PostCallWithErrorResponse(ApiConfig.APPROVE_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getTransporterList();
          //swal("Delivery Accepted Successfully");
          swal({
            icon: "success",
            title: "Registration Success"
          });
          navigate("/DeliveryLists");
        } else {
          swal({
            icon: "warning",
            title: "Registration Rejected",
            text: "Delivery Accepted Successfully",
          });
          navigate("/DeliveryLists");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTransporterList();
  }, []);

  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  {loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <table className="dataList" style={{ minWidth: "900px" }}>
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          {/* <th>
                            <label>Owner Type</label>
                          </th> */}
                          <th>
                            <label>Company Name</label>
                          </th>
                          <th>
                            <label>Contact Phone No</label>
                          </th>
                           <th>
                            <label>Email</label>
                          </th>
                          <th>
                            <label>Region</label>
                          </th>
                          {/* <th>
                            <label>City</label>
                          </th> */}
                          <th>
                            <label>Accept</label>
                          </th>
                          <th
                            // className="text-end"
                            // style={{ paddingRight: "60px" }}
                          >
                            <label>Reject</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          transporterPendingList &&
                            transporterPendingList.map(
                              (transporterList, index) => {
                                return (
                                  <tr className="Rowbgcolor">
                                    <td>
                                      <label>{index + 1}</label>
                                    </td>
                                    {/* <td>
                                      <label>
                                        {transporterList.is_owner === true ? "Owner" : "Driver"}
                                      </label>
                                    </td> */}
                                    <td>
                                      <Link
                                        className="text-decoration-none"
                                        to={
                                          "/DeliveryDetails/" +
                                          transporterList.user_id
                                        }
                                        state={transporterList}
                                      >
                                        <label
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            color: "#385f84",
                                            textDecoration: "underline",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {transporterList.company_name}
                                        </label>
                                      </Link>
                                    </td>
                                     <td>
                                      <label>
                                        {transporterList.user_mobile}
                                      </label>
                                    </td>
                                    <td>
                                      <label>
                                        {transporterList.email}
                                      </label>
                                    </td>
                                    <td>
                                      <label>
                                        {transporterList.user_region}
                                      </label>
                                    </td>
                                    {/* <td>
                                      <label>{transporterList.city}</label>
                                    </td> */}
                                    <td>
                                      {/* <div className="option-dropdown dropdown  p-2"> */}
                                      <Link
                                        //to="/AddDelivery"
                                        // to = {"/AddDelivery/" +
                                        //       transporterList.user_id}
                                        className="regiBTN"
                                        id="accept"
                                        to="#"
                                          onClick={() =>
                                            deliveryAccept(
                                              transporterList.user_id
                                            )
                                          }
                                        // onClick={() =>
                                        //   navigate(
                                        //     "/AddDelivery/" +
                                        //       transporterList.user_id
                                        //   )
                                        //}
                                      >
                                        Accept
                                      </Link>
                                      {/* <ul className="dropdown-menu ">
                                        <li>
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                          >
                                            Edit
                                            <img
                                              src={dropdown_arrow_blue}
                                              className="arrowvehicle"
                                              alt=""
                                            />
                                          </button>
                                        </li>
                                        <li>
                                          <Link
                                            to={
                                              "/TransporterDetails/" +
                                              transporterList.user_id
                                            }
                                            className="text-decoration-none"
                                            state={transporterList}
                                          >
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                            >
                                              View Details
                                            </button>
                                          </Link>
                                        </li>
                                      </ul> */}
                                      {/* </div> */}
                                    </td>
                                    <td>
                                      <label>
                                        {/* {transporterList.contract === "yes" && (
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              transportApprove(
                                                transporterList.user_id
                                              )
                                            }
                                            // onClick={(e) => {
                                            //   handleShow();
                                            // }}
                                            className="regiBTN"
                                            id="accept"
                                          >
                                            Accept
                                            // <img src={cross_green} alt="" />
                                          </Link>
                                        )} */}
                                        {/* {transporterList.contract === "no" && ( */}
                                          {/* <Link
                                            to="#"
                                            onClick={(e) => {
                                              handleShow(
                                                setSelectedId(
                                                  transporterList?.user_id
                                                )
                                              );
                                            }}
                                            className="regiBTN"
                                            id="accept"
                                          >
                                            Accept
                                            //<img src={cross_green} alt="" />
                                          </Link> */}
                                        {/* )} */}
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            transportReject(
                                              transporterList.user_id
                                            )
                                          }
                                          className="regiBTN"
                                          id="reject"
                                        >
                                          Reject
                                        </Link>
                                      </label>
                                    </td>
                                    <Modal
                                      show={show}
                                      onHide={handleClose}
                                      animation={true}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                      className="modal-content-box vehicle-requests-main-modal "
                                    >
                                      <Modal.Header
                                        onClick={() => {
                                          setModal(!modal);
                                        }}
                                        closeButton
                                      ></Modal.Header>

                                      <div>
                                        <p className="text-center popup-heading mt-4">
                                          Please Upload Contract?
                                        </p>
                                      </div>
                                      <div className="btn-popup-modal text-center mt-4">
                                        <button
                                          className="popup-reject-btn m-1 "
                                          onHide={handleClose}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="popup-approve-btn m-1 "
                                          onClick={() => {
                                            setUploadContract(true);
                                            localStorage.setItem(
                                              "uploadContract",
                                              true
                                            );

                                            localStorage.setItem(
                                              "currentId",
                                              selectedId
                                            );

                                            navigate(
                                              "/AddDelivery/" + selectedId
                                            );
                                          }}
                                          onHide={handleClose}
                                        >
                                          Upload Contract
                                        </button>
                                      </div>
                                    </Modal>
                                  </tr>
                                );
                              }
                            )
                          // )
                          // : (
                          //   <div
                          //     className="text-danger"
                          //     style={{
                          //       alignItems: "center",
                          //       display: "flex",
                          //       justifyContent: "center",
                          //     }}
                          //   >
                          //     No Load Available
                          //   </div>
                          // )
                        }
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default DeliveryApproval;
