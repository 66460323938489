import React, { useContext, useEffect, useState } from "react";
// import "../../assets/css/addDriver.scss";
import ic_upload_document from "../../assets/images/AddVehicles/ic_upload_document.svg";
import default_profile from "../../assets/images/default_profile.svg";
import ic_camera from "../../assets/images/camera.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import CountrySelect from "../../sharedComponent/CountrySelect";
import DragAndDropForDrivers from "../../sharedComponent/DragAndDropForDrivers";
import {
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import $ from "jquery";
import CitySelect from "../../sharedComponent/CitySelect";
const AddDriver = () => {
  const [loading, setLoading] = useState(false);
  const [ownerList, setOwnerList] = useState([]);
  const params = useParams();
  let id = params.id;
  const navigate = useNavigate();
  const { mainClass, customerData, ApiCaaling, setApiCalling } =
    useContext(AppContext);
  const [country, setCountry] = useState("");
  const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
  const [ownerDetails, setOwnerDetails] = useState([]);
  const [driverDetails, setDriverDetails] = useState({
    profile_picture: "",
    driver_name: "",
    driver_email: "",
    driver_phone_no: "",
    driver_region: "",
    driver_zone: "",
    driver_woreda: "",
    driver_house_no: "",
    driver_po_number: "",
    driver_country: "",
    driver_dob: "",
    driver_gender: "",
    license_file: "",
    license_grade: "",
    license_issue_date: "",
    license_no: "",
    license_expiry_date: "",
    owner_id: "",
    driver_city: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState({
    driver_name: "",
    driver_email: "",
    driver_phone_no: "",
    driver_region: "",
    driver_zone: "",
    driver_woreda: "",
    driver_country: "",
    driver_gender: "",
    license_file: "",
    license_grade: "",
    license_issue_date: "",
    license_no: "",
    license_expiry_date: "",
    owner_id: "",
    driver_city: "",
    password: "",
  });
  const getTransporterList = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELIVERY_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        setOwnerList(res.json.transporter_list);
      }
    });
  };
  useEffect(() => {
    getTransporterList();
    if (id) {
      setApiCalling(true);
      postWithAuthCallWithErrorResponse(
        ApiConfig.DRIVER_DETAILS,
        JSON.stringify({ ...customerData, driver_id: id })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false);
          if (res.json.result) {
            let driver_details = res.json.driver_details;
            setDriverDetails({
              profile_picture: driver_details.profile_pic,
              driver_name: driver_details.driver_name,
              driver_email: driver_details.email,
              driver_phone_no: driver_details.mobile_number,
              driver_region: driver_details.region,
              driver_zone: driver_details.zone,
              driver_woreda: driver_details.woreda,
              driver_house_no: driver_details.house_no,
              driver_po_number: driver_details.po_box,
              driver_country: driver_details.country,
              driver_dob: driver_details.birthdate,
              driver_gender: driver_details.gender,
              license_file: driver_details.license_document,
              license_grade: driver_details.license_grade,
              license_issue_date: driver_details.license_issue_date,
              license_no: driver_details.licence_number,
              license_expiry_date: driver_details.license_expiry_date,
            });
            setOwnerDetails(res.json.owner_details);
          }
        })
        .catch((err) => {
          setApiCalling(false);
          console.log(err);
        });
    }
  }, []);

  const Add = () => {
    if (
      driverDetails.driver_name === "" ||
      driverDetails.driver_email === "" ||
      driverDetails.driver_phone_no === "" ||
      driverDetails.driver_region === "" ||
      driverDetails.driver_zone === "" ||
      driverDetails.driver_woreda === "" ||
      driverDetails.driver_country === "" ||
      driverDetails.driver_city === "" ||
      driverDetails.driver_gender === "" ||
      driverDetails.owner_id === "" ||
      (!id && driverDetails.password === "") ||
      !driverDetails.license_file ||
      driverDetails.license_grade === "" ||
      driverDetails.license_issue_date === "" ||
      driverDetails.license_no === "" ||
      driverDetails.license_expiry_date === ""
    ) {
      if (driverDetails.driver_name === "") {
        setErrMsg({ ...errMsg, driver_name: "Drivers name is required" });
        window.scrollTo(0, 0);
        return;
      }
      if (driverDetails.driver_email === "") {
        setErrMsg({ ...errMsg, driver_email: "Drivers Email ID is required" });
        window.scrollTo(0, 100);
        return;
      }
      if (driverDetails.driver_phone_no === "") {
        setErrMsg({
          ...errMsg,
          driver_phone_no: "Drivers Phone number is required",
        });
        window.scrollTo(0, 100);
        return;
      }
      if (driverDetails.driver_region === "") {
        setErrMsg({
          ...errMsg,
          driver_region: "Drivers Region number is required",
        });
        window.scrollTo(0, 100);
        return;
      }
      if (driverDetails.driver_zone === "") {
        setErrMsg({
          ...errMsg,
          driver_zone: "Drivers Zone number is required",
        });
        window.scrollTo(0, 200);
        return;
      }
      if (driverDetails.driver_woreda === "") {
        setErrMsg({ ...errMsg, driver_woreda: "Drivers Woreda is required" });
        window.scrollTo(0, 200);
        return;
      }
      if (driverDetails.driver_country === "") {
        setErrMsg({ ...errMsg, driver_country: "Drivers Country is required" });
        window.scrollTo(0, 400);
        return;
      }
      if (driverDetails.driver_city === "") {
        setErrMsg({ ...errMsg, driver_city: "Drivers city is required" });
        window.scrollTo(0, 400);
        return;
      }
      if (driverDetails.driver_gender === "") {
        setErrMsg({ ...errMsg, driver_gender: "Drivers Gender is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (driverDetails.owner_id === "") {
        setErrMsg({ ...errMsg, owner_id: "Please select the transporter" });
        window.scrollTo(0, 600);
        return;
      }
      if (driverDetails.password === "") {
        setErrMsg({ ...errMsg, password: "Password is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (!driverDetails.license_file) {
        setErrMsg({ ...errMsg, license_file: "License File is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (driverDetails.license_grade === "") {
        setErrMsg({ ...errMsg, license_grade: "License Grade is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (driverDetails.license_issue_date === "") {
        setErrMsg({
          ...errMsg,
          license_issue_date: "License Issue date is required",
        });
        window.scrollTo(0, 800);
        return;
      }
      if (driverDetails.license_no === "") {
        setErrMsg({ ...errMsg, license_no: "License No is required" });
        window.scrollTo(0, 800);
        return;
      }
      if (driverDetails.license_expiry_date === "") {
        setErrMsg({
          ...errMsg,
          license_expiry_date: "License Expiry is required",
        });
        window.scrollTo(0, 1000);
        return;
      }
    } else {
      if (
        new Date(driverDetails.license_issue_date) >
        new Date(driverDetails.license_expiry_date)
      ) {
        setErrMsg({
          ...errMsg,
          license_expiry_date:
            "License Expiry  date should be greater than issue date",
        });
        window.scrollTo(0, 1000);
        return;
      }
      if (!validateEmail(driverDetails.driver_email)) {
        setErrMsg({ ...errMsg, driver_email: "Enter email in proper format" });
        window.scrollTo(0, 1000);
        return;
      }
      setApiCalling(true);
      let formdata = new FormData();
      formdata.append("api_key", customerData.api_key);
      formdata.append("user_id", customerData.user_id);
      formdata.append("customer_id", customerData.customer_id);
      formdata.append("driver_name", driverDetails.driver_name);
      formdata.append("driver_email", driverDetails.driver_email);
      formdata.append("driver_phone_no", driverDetails.driver_phone_no);
      formdata.append("driver_gender", driverDetails.driver_gender);
      formdata.append("owner_id", driverDetails.owner_id);
      formdata.append("driver_city", driverDetails.driver_city);
      formdata.append("driver_country", driverDetails.driver_country);
      formdata.append("driver_dob", driverDetails.driver_dob);
      formdata.append("license_no", driverDetails.license_no);
      formdata.append("license_grade", driverDetails.license_grade);
      formdata.append("license_issue_date", driverDetails.license_issue_date);
      formdata.append("license_expiry_date", driverDetails.license_expiry_date);
      formdata.append("driver_zone", driverDetails.driver_zone);
      formdata.append("driver_region", driverDetails.driver_region);
      formdata.append("driver_woreda", driverDetails.driver_woreda);
      formdata.append("driver_house_no", driverDetails.driver_house_no);
      formdata.append("driver_po_number", driverDetails.driver_po_number);
      formdata.append("password", driverDetails.password);
      driverDetails.license_file.name &&
        formdata.append("license_file", driverDetails.license_file);
      formdata.append("driver_po_number", driverDetails.driver_po_number);
      driverDetails.profile_picture &&
        formdata.append("profile_picture", driverDetails.profile_picture);
      id && formdata.append("driver_id", id);
      multipartPostCallWithErrorResponse(
        id ? ApiConfig.EDIT_DRIVER : ApiConfig.ADD_DRIVER,
        formdata
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false);
          swal(res.json.message);
          if (res.json.result) {
            navigate("/driverlist");
          }
        })
        .catch((err) => {
          setApiCalling(false);
          console.log(err);
        });
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <>
      {/* <Sidebar />
      <AbhayHeader /> */}
      <main className={`main-section-dashboard scroll-style ${mainClass} `}>
        <div className="main-body-AddDriver">
          {/* Driver Detail */}

          <section className="main-vehicle-info  mt-4 ">
            <div className="content">
              <div className="row mt-6">
                <div className="col-12">
                  <h5 className="card-heading">Driver Details</h5>
                  <hr />
                  <div className="col-lg-12">
                    <div className="cx-profile-pic">
                      <img
                        className="cx-pdefault"
                        src={
                          !driverDetails.profile_picture
                            ? default_profile
                            : driverDetails.profile_picture.length
                            ? ApiConfig.BASE_URL_FOR_IMAGES +
                              driverDetails.profile_picture
                            : driverDetails.profile_picture &&
                              URL.createObjectURL(driverDetails.profile_picture)
                        }
                        style={{
                          display: "inline-block",
                          height: "120px",
                          width: "120px",
                          borderRadius: "50%",
                        }}
                      />
                      <div className="camera-icon">
                        <input
                          type="file"
                          accept="image/*"
                          id="file"
                          onChange={(e) => {
                            setDriverDetails({
                              ...driverDetails,
                              profile_picture: e.target.files[0],
                            });
                            if (
                              !allowedFileTypes.includes(e.target.files[0].type ) ||
                              e.target.files[0].size > 5242880
                            ) {
                              swal({
                                icon: "error",
                                title: "Invalid file type",
                                text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                              });
                              setDriverDetails({
                                ...driverDetails,
                                profile_picture: "",
                              });
                            }
                          }}
                        />
                        <label
                          htmlFor="file"
                          className="upload-profile-image"
                          style={{ cursor: "pointer" }}
                        >
                          <img className="cx-camera" src={ic_camera} /> Upload
                          Profile Image
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          value={driverDetails.driver_name}
                          aria-describedby="emailHelp"
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_name: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_name: e.target.value,
                            });
                            // if (
                            //   !allowedFileTypes.includes(e.target.files[0].type) ||
                            //   e.target.files[0].size > 5242880
                            // ) {
                            //   swal({
                            //     icon: "error",
                            //     title: "Invalid file type",
                            //     text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                            //   });
                            //   setDriverDetails({
                            //     ...driverDetails,
                            //     driver_name: "",
                            //   });
                            // }
                          }}
                        />
                        {errMsg.driver_name.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_name}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_email}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_email: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errMsg.driver_email.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.driver_email}</span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          maxLength={10}
                          value={driverDetails.driver_phone_no}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_phone_no: "" });
                            const re = /^[0-9\b]+$/;
                            if (!isNaN(e.target.value)) {
                              setErrMsg({ ...errMsg, driver_phone_no: "" });
                              setDriverDetails({
                                ...driverDetails,
                                driver_phone_no: e.target.value,
                              });
                            } else {
                              setErrMsg({
                                ...errMsg,
                                driver_phone_no:
                                  "Phone number does not contains a alphabet",
                              });
                            }
                          }}
                        />
                        {errMsg.driver_phone_no.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_phone_no}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                     <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Region</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, driver_region: "" });
                          setDriverDetails({ ...driverDetails, driver_region: e.target.value });
                        }}
                      >
                        <option selected>Please Select</option>
                        <option value="Addis Ababa ">Addis Ababa </option>
                        <option value="Afar">Afar</option>
                        <option value="Amhara ">Amhara </option>
                        <option value="Benishangul Gumuz ">
                          Benishangul Gumuz{" "}
                        </option>
                        <option value="Dire Dawa ">Dire Dawa </option>
                        <option value="Gambella  ">Gambella </option>
                        <option value="Harari  ">Harari </option>
                        <option value="Oromia ">Oromia </option>
                        <option value="Sidama ">Sidama </option>
                        <option value="Somali ">Somali </option>
                        <option value="South West EthiopianPeople ">
                          South West EthiopianPeople{" "}
                        </option>
                        <option value="Southern Nation, Nationalities And People  ">
                          Southern Nation, Nationalities And People{" "}
                        </option>
                        <option value="Tigiray  ">Tigiray </option>
                      </select>
                      
                      {errMsg.driver_region.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.driver_region}</span>
                        </div>
                      )}
                    </div> 
                  </div>

                    {/* <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Region</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_region}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_region: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_region: e.target.value,
                            });
                          }}
                        />
                        {errMsg.driver_region.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_region}</span>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Zone</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_zone}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_zone: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_zone: e.target.value,
                            });
                          }}
                        />
                        {errMsg.driver_zone.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_zone}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Woreda</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_woreda}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_woreda: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_woreda: e.target.value,
                            });
                          }}
                        />
                        {errMsg.driver_woreda.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_woreda}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">House No.</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_house_no}
                          onChange={(e) => {
                            setDriverDetails({
                              ...driverDetails,
                              driver_house_no: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">P.O.Box</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_po_number}
                          onChange={(e) => {
                            setDriverDetails({
                              ...driverDetails,
                              driver_po_number: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <CountrySelect
                          setCountry={(country) => {
                            setErrMsg({ ...errMsg, driver_country: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_country: country.value,
                            });
                            setCountry(country.isoCode);
                          }}
                          country={driverDetails.driver_country}
                        />
                        {errMsg.driver_country.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_country}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <CitySelect
                        country={country}
                        setCity={(city) => {
                          setErrMsg({ ...errMsg, driver_city: "" });
                          setDriverDetails({
                            ...driverDetails,
                            driver_city: city.value,
                          });
                        }}
                        city={driverDetails.driver_city}
                      />
                      {errMsg.driver_city.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.driver_city}</span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={driverDetails.driver_dob}
                          onChange={(e) => {
                            setDriverDetails({
                              ...driverDetails,
                              driver_dob: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Gender</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          defaultValue={driverDetails.driver_gender}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, driver_gender: "" });
                            setDriverDetails({
                              ...driverDetails,
                              driver_gender: e.target.value,
                            });
                          }}
                        >
                          <option value="M" selected>
                            Select Gender
                          </option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </select>
                        {errMsg.driver_gender.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.driver_gender}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Delivery</label>
                        <input
                          type={"text"}
                          className="form-control"
                          aria-label="Default select example"
                          value={ownerDetails.user_name}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, owner_id: "" });
                            setDriverDetails({
                              ...driverDetails,
                              owner_id: e.target.value,
                            });
                          }}
                        />
                        {errMsg.owner_id.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.owner_id}</span>
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Delivery</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={ownerDetails.user_id}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, owner_id: "" });
                            setDriverDetails({
                              ...driverDetails,
                              owner_id: e.target.value,
                            });
                          }}
                        >
                          <option value="M">Select Delivery</option>
                          {ownerList.map((owner) => {
                            return (
                              <option value={owner.user_id}>
                                {owner.company_name}
                              </option>
                            );
                          })}
                        </select>
                        {errMsg.owner_id.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.owner_id}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <input
                          type={"password"}
                          className="form-control"
                          aria-label="Default select example"
                          value={driverDetails.password}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, password: "" });
                            setDriverDetails({
                              ...driverDetails,
                              password: e.target.value,
                            });
                          }}
                        />
                        {errMsg.password.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.password}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Driver Documents */}
          <section className="main-vehicle-info driver-documents mt-4">
            <div className="content">
              <div className="row ">
                <div className="col-12 ">
                  <h5 className="card-heading">Driver Documents</h5>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <DragAndDropForDrivers
                        license_file={driverDetails.license_file}
                        setFile={(file) => {
                          setErrMsg({ ...errMsg, license_file: file });
                          setDriverDetails({
                            ...driverDetails,
                            license_file: file,
                          });
                          if (
                            !allowedFileTypes.includes(file.type) ||
                            file.size > 5242880
                          ) {
                            swal({
                              icon: "error",
                              title: "Invalid file type",
                              text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                            });
                            setDriverDetails({
                              ...driverDetails,
                              license_file: "",
                            });
                          }
                        }}
                      />
                      {errMsg.license_file.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.license_file}</span>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mt-4">
                            <label htmlFor="exampleInputEmail1">
                              License Grade
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              value={driverDetails.license_grade}
                              aria-describedby="emailHelp"
                              onChange={(e) => {
                                setErrMsg({ ...errMsg, license_grade: "" });
                                setDriverDetails({
                                  ...driverDetails,
                                  license_grade: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {errMsg.license_grade.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.license_grade}</span>
                            </div>
                          )}

                          <div className="form-group mt-4">
                            <label htmlFor="exampleInputEmail1">
                              License Issue Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              value={driverDetails.license_issue_date}
                              onChange={(e) => {
                                setErrMsg({
                                  ...errMsg,
                                  license_issue_date: "",
                                });
                                setDriverDetails({
                                  ...driverDetails,
                                  license_issue_date: e.target.value,
                                });
                              }}
                            />
                            {errMsg.license_issue_date.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.license_issue_date}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mt-4">
                            <label htmlFor="exampleInputEmail1">
                              License Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              value={driverDetails.license_no}
                              onChange={(e) => {
                                setErrMsg({ ...errMsg, license_no: "" });
                                setDriverDetails({
                                  ...driverDetails,
                                  license_no: e.target.value,
                                });
                              }}
                            />
                            {errMsg.license_no.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.license_no}</span>
                              </div>
                            )}
                          </div>
                          <div className="form-group mt-4">
                            <label htmlFor="exampleInputEmail1">
                              License Expiry Date
                            </label>

                            <input
                              type="date"
                              className="form-control datepicker"
                              id="session-date"
                              data-date-format="mm/dd/yyyy"
                              aria-describedby="emailHelp"
                              value={driverDetails.license_expiry_date}
                              onClick={() => {
                                document.getElementById("session-date").focus();
                                // You could write code to toggle this
                              }}
                              onChange={(e) => {
                                setErrMsg({
                                  ...errMsg,
                                  license_expiry_date: "",
                                });
                                setDriverDetails({
                                  ...driverDetails,
                                  license_expiry_date: e.target.value,
                                });
                              }}
                            />
                            {errMsg.license_expiry_date.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.license_expiry_date}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="/DriverList">
              <button type="cancel" className="cancel-btn">
                Cancel
              </button>
            </a>
            {/* <Link to="/VehicleDriverDetailsHeader"> */}
            <button type="submit" className="continue-btn" onClick={Add}>
              Add
            </button>
            {/* </Link> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default AddDriver;
