import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import ic_document from "../../assets/images/document_image.png";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import user_1 from "../../assets/images/Dashboard/user_2.svg";
import ChangeDriver from "./ChangeDriver";
import { Modal } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import FreightsDetails from "../LiveTracking/OngoingFreightsMenu";
const ViewDetails = () => {
  const params = useParams();
  const id = params.id;
  const [show, setShow] = useState(false);
  const frightdetail = useLocation().state;
  const [loading, setLoading] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { customerData } = useContext(AppContext);
  const { mainClassB } = useContext(AppContext);
  const [frightDetails, setFrightDetails] = useState({});
  const [currentVehicle, setCurrentVehicle] = useState({});

  $(document).ready(function () {
    $(".dataList").DataTable();
  });
  const getFrightDetails = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.FRIGHT_DETAILS,
      JSON.stringify({ ...customerData, load_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) {
        setFrightDetails(res.json.load_details);
        setVehicleList(res.json.vehicle_list);
      }
    });
  };

  const vehicleDetails = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECTORDER_VEHICLELIST,
      JSON.stringify({
        ...customerData,
        load_id: id,
        transporter_id: frightdetail?.transporter_id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setVehicleList(res.json.vehicle_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getFrightDetails();
    vehicleDetails();
  }, []);

  return (
    <div>
      <main className="vehicle-popup">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className={`header-body ${mainClassB}`} id="cxMainB">
            <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <Link
                  to="/VehiclesDetails"
                  className="nav-link tab-link active"
                  id="pills-goodsdetails-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-goodsdetails"
                  type="button"
                  role="tab"
                  aria-controls="pills-goodsdetails"
                  aria-selected="true"
                >
                  Goods Details
                </Link>
              </li>
              {/* <li className="nav-item" role="presentation">
                <Link
                  to="/VehiclesDetails"
                  className="nav-link tab-link "
                  id="pills-driverdetails-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-driverdetails"
                  type="button"
                  role="tab"
                  aria-controls="pills-driverdetails"
                  aria-selected="true"
                  // onClick={() => setShow(true)}
                >
                  Vehicle Details
                </Link>
              </li> */}
              {/* <li className="nav-item" role="presentation">
     <Link
       to="/DriverDetails"
       className="nav-link tab-link"
       id="pills-customerdetails-tab"
       data-bs-toggle="pill"
       data-bs-target="#pills-customerdetails"
       type="button"
       role="tab"
       aria-controls="pills-customerdetails"
       aria-selected="false"
     >
       Driver Details
     </Link>
   </li> */}
            </ul>
            <div
              className="tab-content "
              id="pills-tabContent"
              style={{ margin: "20px", overflow: "auto" }}
            >
              {/* Goods details */}

              <div
                className="tab-pane fade show active "
                id="pills-goodsdetails"
                role="tabpanel"
                aria-labelledby="pills-goodsdetails-tab"
              >
                {/* top  */}
                <div className="top-content common-body mt-4 d-flex justify-content-between align-items-center">
                  <div className="driver-profile-details d-flex align-items-center">
                    <div className="driver-name">
                      <div className="title-driver">
                        <h4 className="name-driver">{frightDetails.shipper}</h4>
                      </div>
                      <div className="rating">
                        <img src={star_highlighted} alt="" />
                        <img src={star_highlighted} alt="" />
                        <img src={star_highlighted} alt="" />
                        <img src={star_highlighted} alt="" />
                        <img src={star_grey} alt="" />
                        <span>({frightDetails.rating})</span>
                      </div>
                    </div>
                  </div>
                  <div className="view-detail-btn-load">
                    <Link
                      to={"/ShipperDetails/" + frightDetails.trip_creator_id}
                      style={{ color: "#506c87" }}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
                {/* From details section */}
                <section className="from-details">
                  <div className="common-body mt-4 p-3">
                    <div className="row">
                      <div className="top-content ">
                        <h5 className="card-heading">Delivery Date</h5>
                        <hr />
                      </div>
                      <div className="col-lg-3 col-md-6 ">
                        <label htmlFor="">Trip Delivery Date</label>
                        <p className="data">{frightDetails.trip_delivery_date}</p>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Estimated Start Time</label>
                        <p className="data">{frightDetails.trip_start_time}</p>
                      </div> */}
                      {/* <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Country</label>
                        <p className="data">
                          {frightDetails.trip_start_country}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">City</label>
                        <p className="data">{frightDetails.trip_start_city}</p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Address</label>
                        <p className="data">
                          {frightDetails.trip_start_address}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </section>
                <section className="from-details">
                  <div className="common-body mt-4 p-3">
                    <div className="row">
                      <div className="top-content ">
                        <h5 className="card-heading">From</h5>
                        <hr />
                      </div>
                      {/* <div className="col-lg-3 col-md-6 ">
                        <label htmlFor="">Estimated Start Date</label>
                        <p className="data">{frightDetails.trip_start_date}</p>
                      </div> */}
                      {/* <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Estimated Start Time</label>
                        <p className="data">{frightDetails.trip_start_time}</p>
                      </div> */}
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Country</label>
                        <p className="data">
                          {frightDetails.trip_start_country}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">City</label>
                        <p className="data">{frightDetails.trip_start_city}</p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Address</label>
                        <p className="data">
                          {frightDetails.trip_start_address}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                {/* To details section */}
                <section className="to-details">
                  <div className="common-body mt-4 p-3">
                    <div className="row">
                      <div className="top-content ">
                        <h5 className="card-heading">To</h5>
                        <hr />
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Estimated End Date</label>
                        <p className="data">{frightDetails.trip_end_date}</p>
                      </div> */}
                      {/* <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Estimated End Time</label>
                        <p className="data">{frightDetails.trip_end_time}</p>
                      </div> */}
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Country</label>
                        <p className="data">{frightDetails.trip_end_country}</p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">City</label>
                        <p className="data">{frightDetails.trip_end_city}</p>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <label htmlFor="">Address</label>
                        <p className="data">{frightDetails.trip_end_address}</p>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Goods details */}
                <section className="goods-details mb-4">
                  <div className="common-body mt-4 p-3">
                    <div className="row">
                      <div className="top-content ">
                        <h5 className="card-heading">Goods Details</h5>
                        <hr />
                      </div>
                      {/* left section */}
                      <div className="col-lg-6">
                        <div className="image">
                          <div
                            id="carouselExampleControls"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              {frightDetails && frightDetails.trip_images ? (
                                frightDetails.trip_images.map((img, index) => {
                                  return (
                                    <div
                                      className={
                                        index === 0
                                          ? "carousel-item active"
                                          : "carousel-item"
                                      }
                                      key={img.vehicle_image_id}
                                    >
                                      <img
                                        src={
                                          ApiConfig.BASE_URL_FOR_IMAGES +
                                          img.trip_image_url
                                        }
                                        className="d-block "
                                        alt={"..."}
                                        width="200px"
                                        height={"200px"}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <div>No Images</div>
                              )}
                            </div>
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* right section */}
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4 mt-4">
                            <label htmlFor="">Reference Number</label>
                            <p className="data">
                              {frightDetails.trip_reference_no}
                            </p>
                          </div>
                          <div className="col-lg-4 mt-4">
                            <label htmlFor="">Company Name</label>
                            <p className="data">{frightDetails.shipper}</p>
                          </div>
                          <div className="col-lg-4 mt-4">
                            <label htmlFor="">Cargo Type</label>
                            <p className="data">{frightDetails.cargo_type}</p>
                          </div>
                          
                          {frightDetails.container_type && 
                      <div className="col-lg-4 mt-4">
                          <label htmlFor="">Container Type</label>
                                <p className="data">{frightDetails && frightDetails.container_type ? 
                                     frightDetails.container_type : "No Container"}</p>
                  </div>}

                  <div className="col-lg-4 mt-4">
                      <label htmlFor="">Quantity</label>
                          <p className="data">{frightDetails && frightDetails.cargo_type === "Container" ? 
                              frightDetails.trip_container_quantity + " Containers" : 
                              frightDetails.cargo_type === "Vehicle" ? frightDetails.quantity + " Vehicles" : 
                              (frightDetails.cargo_type === "Bulk" || frightDetails.cargo_type === "Break bulk") ?
                              frightDetails.quantity + " Quintals" : " - "}
                          </p>
                  </div>
                          
                          {/* <div className="col-lg-4 mt-4">
                            <label htmlFor="">Delivery Type</label>
                            <p className="data">
                              {frightDetails.delivery_type}
                            </p>
                          </div> */}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </section>
                {/* Document Details */}
                <section className="documents-details">
                  <div className="common-body mt-4 p-3">
                    <div className="top-content ">
                      <h5 className="card-heading">Documents Details</h5>
                      <hr />
                      <div className="profile-contact-person row mt-4">
                        {/* <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                          <label htmlFor="">Packing List</label>
                          <div className="d-flex mx-1">
                            {frightDetails?.trip_packing_list?.includes(
                              ".pdf"
                            ) ? (
                              <a
                                className="text-dark"
                                href={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  frightDetails.trip_packing_list
                                }
                                target="_blank"
                              >
                                {frightDetails?.trip_packing_list?.slice(
                                  "uploads/loads/".length
                                )}
                              </a>
                            ) : (
                              <img
                                src={
                                  frightDetails.trip_packing_list
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_packing_list
                                    : ic_document
                                }
                                alt=""
                                className="doc-img-style"
                              />
                            )}
                            <div className="option-dropdown dropdown">
                              <a
                                href=""
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={ic_options_menu}
                                  className="dot-icon"
                                  alt=""
                                />
                              </a>
                              <ul className="dropdown-menu ">
                                <li>
                                  <a
                                    href={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_packing_list
                                    }
                                    className="preview_link"
                                    target="_blank"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#GC_Modal"
                                  >
                                    Download
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                        {/* {frightDetails?.trip_bill_of_landing !== "" && (
                          <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                            <label htmlFor="">Bill of Lading</label>
                            <div className=" d-flex mx-1">
                              {frightDetails?.trip_bill_of_landing?.includes(
                                ".pdf"
                              ) ? (
                                <a
                                  className="text-dark"
                                  href={
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                    frightDetails.trip_bill_of_landing
                                  }
                                  target="_blank"
                                >
                                  {frightDetails?.trip_bill_of_landing?.slice(
                                    "uploads/loads/".length
                                  )}
                                </a>
                              ) : (
                                <img
                                  src={
                                    frightDetails.trip_bill_of_landing
                                      ? ApiConfig.BASE_URL_FOR_IMAGES +
                                        frightDetails.trip_bill_of_landing
                                      : ic_document
                                  }
                                  alt=""
                                  className="doc-img-style"
                                />
                              )}
                              <div className="option-dropdown dropdown">
                                <a
                                  href=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={ic_options_menu}
                                    className="dot-icon"
                                    alt=""
                                  />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <a
                                      href={
                                        ApiConfig.BASE_URL_FOR_IMAGES +
                                        frightDetails.trip_bill_of_landing
                                      }
                                      className="preview_link"
                                      target="_blank"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#GC_Modal"
                                    >
                                      Download
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )} */}
                        <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                          <label htmlFor="">
                            VAT Registration Certificate
                          </label>
                          <div className=" d-flex mx-1">
                            {frightDetails?.trip_vat_reg_certificate?.includes(".pdf") ? (
                              <a
                                className="text-dark"
                                href={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  frightDetails.trip_vat_reg_certificate
                                }
                                target="_blank"
                              >
                                {frightDetails?.trip_vat_reg_certificate?.slice(
                                  "uploads/loads/".length
                                )}
                              </a>
                            ) : (
                              <img
                                src={
                                  frightDetails.trip_vat_reg_certificate
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_vat_reg_certificate
                                    : ic_document
                                }
                                alt=""
                                className="doc-img-style"
                              />
                            )}
                            <div className="option-dropdown dropdown">
                              <a
                                href=""
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={ic_options_menu}
                                  className="dot-icon"
                                  alt=""
                                />
                              </a>
                              <ul className="dropdown-menu ">
                                <li>
                                  <a
                                    href={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_vat_reg_certificate
                                    }
                                    className="preview_link"
                                    target="_blank"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#GC_Modal"
                                  >
                                    Download
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                          <label htmlFor="">
                            Tax ID No
                          </label>
                          <div className=" d-flex mx-1">
                            {frightDetails?.trip_tax_id_no?.includes(".pdf") ? (
                              <a
                                className="text-dark"
                                href={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  frightDetails.trip_tax_id_no
                                }
                                target="_blank"
                              >
                                {frightDetails?.trip_tax_id_no?.slice(
                                  "uploads/loads/".length
                                )}
                              </a>
                            ) : (
                              <img
                                src={
                                  frightDetails.trip_tax_id_no
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_tax_id_no
                                    : ic_document
                                }
                                alt=""
                                className="doc-img-style"
                              />
                            )}
                            <div className="option-dropdown dropdown">
                              <a
                                href=""
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={ic_options_menu}
                                  className="dot-icon"
                                  alt=""
                                />
                              </a>
                              <ul className="dropdown-menu ">
                                <li>
                                  <a
                                    href={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_tax_id_no
                                    }
                                    className="preview_link"
                                    target="_blank"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#GC_Modal"
                                  >
                                    Download
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                          <label htmlFor="">
                            Trade License
                          </label>
                          <div className=" d-flex mx-1">
                            {frightDetails?.trip_trade_license?.includes(".pdf") ? (
                              <a
                                className="text-dark"
                                href={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  frightDetails.trip_trade_license
                                }
                                target="_blank"
                              >
                                {frightDetails?.trip_trade_license?.slice(
                                  "uploads/loads/".length
                                )}
                              </a>
                            ) : (
                              <img
                                src={
                                  frightDetails.trip_trade_license
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_trade_license
                                    : ic_document
                                }
                                alt=""
                                className="doc-img-style"
                              />
                            )}
                            <div className="option-dropdown dropdown">
                              <a
                                href=""
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={ic_options_menu}
                                  className="dot-icon"
                                  alt=""
                                />
                              </a>
                              <ul className="dropdown-menu ">
                                <li>
                                  <a
                                    href={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      frightDetails.trip_trade_license
                                    }
                                    className="preview_link"
                                    target="_blank"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#GC_Modal"
                                  >
                                    Download
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                </section>
              </div>

              {/* Vehicle details */}
              <div
                className="tab-pane fade show "
                id="pills-driverdetails"
                role="tabpanel"
                aria-labelledby="pills-driverdetails-tab"
              >
                {vehicleList.map((vehicle, index) => {
                  return (
                    <div
                      className="top-content common-body mt-4 d-flex justify-content-between"
                      key={"vehicle" + index}
                    >
                      <div
                        className="driver-profile-details d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCurrentVehicle(vehicle);
                          handleShow();
                        }}
                      >
                        <div className="profile-pic me-3">
                          <img src={user_1} alt="" />
                        </div>
                        <div className="driver-name">
                          <div className="title-driver">
                            <h4 className="name-driver">
                              {vehicle.driver_name}
                            </h4>
                          </div>
                          <div className="rating">
                            <img src={star_highlighted} alt="" />
                            <img src={star_highlighted} alt="" />

                            <img src={star_highlighted} alt="" />
                            <img src={star_highlighted} alt="" />
                            <img src={star_grey} alt="" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu vehicle-detail-live">
                          <li>
                            <Link to="#" className="text-decoration-none">
                              <button className="dropdown-item" type="button">
                                View Details
                                <img
                                  src={dropdown_arrow_blue}
                                  className="arrowvehicle"
                                  alt=""
                                />
                              </button>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/ChangeDriver"
                              className="text-decoration-none"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasDriverAccount"
                              aria-controls="offcanvasRightAccount"
                            >
                              <button className="dropdown-item " type="button">
                                View vehicles
                              </button>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="text-decoration-none"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasDriverAccount"
                              aria-controls="offcanvasRightAccount"
                            >
                              <button className="dropdown-item " type="button">
                                View Contract
                              </button>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="text-decoration-none"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasDriverAccount"
                              aria-controls="offcanvasRightAccount"
                            >
                              <button className="dropdown-item " type="button">
                                Call
                              </button>
                            </Link>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id=""
          className="Vehicle-popup-offered"
        >
          <div className="mb-2">
            <Modal.Header closeButton>
              <h4 className="heading-select-transporter ">Vehicle Details</h4>
            </Modal.Header>
          </div>
          <div>
            <section
              className="vehicle-detail-section mb-0 common-body p-3 mt-2"
              style={{
                height: "auto",
                boxShadow: "none",
                borderTop: "1px solid #F6F2EF",
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="image">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={Skyscrapers}
                            className="d-block "
                            alt={"..."}
                          />
                        </div>
                        {currentVehicle &&
                          currentVehicle.vehicle_images &&
                          currentVehicle.vehicle_images.map((img, index) => {
                            return (
                              <div className="carousel-item">
                                <img
                                  width="515px"
                                  height="300px"
                                  src={
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                    img.trip_image_url
                                  }
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                            );
                          })}
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right-section">
                    <div className="right-top-content d-flex justify-content-between">
                      <div className="title-vehicle">
                        <h4 className="name-vehicle">
                          {currentVehicle.vehicle_model_no}
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 left item mt-2">
                        <label>Plate Number</label>
                        <p className="data">
                          {currentVehicle && currentVehicle.plate_number}
                        </p>
                      </div>
                      <div className="col-lg-4 middel col-sm-6 item mt-2">
                        <label>Vehicle Type</label>
                        <p className="data">
                          {currentVehicle && currentVehicle.vehicle_type}
                        </p>
                      </div>
                      <div className="col-lg-4 middel col-sm-6 item mt-2">
                        <label>Vehicle Model No</label>
                        <p className="data">
                          {currentVehicle && currentVehicle.vehicle_model_no}
                        </p>
                      </div>
                      <hr />
                      <h5 className="card-heading">Driver Details</h5>
                      <div className="col-lg-4 col-sm-6 left item mt-2">
                        <label>Name</label>
                        <p className="data">
                          {currentVehicle && currentVehicle.driver_name}
                        </p>
                      </div>
                      <div className="col-lg-4 col-sm-6 left item mt-2">
                        <label>Nationality</label>
                        <p className="data">
                          {currentVehicle && currentVehicle.nationality}
                        </p>
                      </div>
                      <div className="col-lg-4 col-sm-6 left item mt-2">
                        <label>Licence Number</label>
                        <p className="data">
                          {currentVehicle &&
                            currentVehicle.driver_licence_number}
                        </p>
                      </div>
                      {currentVehicle.trailer_vehicle_type && (
                        <>
                          <hr />
                          <h5 className="card-heading">Trailer Details</h5>
                          <div className="col-lg-4 col-sm-6 left item mt-2">
                            <label>Trailer Plate Number</label>
                            <p className="data">
                              {currentVehicle &&
                                currentVehicle.trailer_plate_no}
                            </p>
                          </div>
                          <div className="col-lg-4 col-sm-6 left item mt-2">
                            <label>Trailer Vehicle Type</label>
                            <p className="data">
                              {currentVehicle &&
                                currentVehicle.trailer_vehicle_type}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default ViewDetails;
