import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import ShipperRejectedList from "./ShipperRejectedList";
import TransporterRejectedList from "./TransporterRejectedList";

const ApprovalHeader = () => {
  const { mainClassB } = useContext(AppContext);
  const [searchText, setSearchText] = useState("");
  return (
    <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
      <ul
        className="nav nav-pills mb-1 searchOrder"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <Link
            to="/TransporterRejectedList"
            className="nav-link tab-link active"
            id="pills-driverdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-driverdetails"
            type="button"
            role="tab"
            aria-controls="pills-driverdetails"
            aria-selected="true"
          >
            Shipper
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/ShipperRejectedList"
            className="nav-link tab-link"
            id="pills-customerdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-customerdetails"
            type="button"
            role="tab"
            aria-controls="pills-customerdetails"
            aria-selected="false"
          >
            Delivery
          </Link>
        </li>
      </ul>
      <div
        className="tab-content "
        id="pills-tabContent"
        style={{ marginBottom: "80px", overflow: "auto" }}
      >
        <div
          className="tab-pane fade show active"
          id="pills-driverdetails"
          role="tabpanel"
          aria-labelledby="pills-driverdetails-tab"
        >
          <ShipperRejectedList />
        </div>

        <div
          className="tab-pane fade"
          id="pills-customerdetails"
          role="tabpanel"
          aria-labelledby="pills-customerdetails-tab"
        >
          <TransporterRejectedList />
        </div>
      </div>
    </main>
  );
};

export default ApprovalHeader;
