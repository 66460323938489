import React, { useContext, useEffect, useState } from "react";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import $ from "jquery";
import dt from "datatables.net";
import { Modal, Button } from "react-bootstrap";
import dataTablesDataTables from "datatables.net-dt/js/dataTables.dataTables";
import Filters from "../../sharedComponent/Filters";
const SelectTransporter = () => {
  const [originalData, setOriginalData] = useState([]);
  const [selected, setSelected] = useState("");
  const [specifications, setSpecifications] = useState([]);
  let data = JSON.parse(localStorage.getItem("currentVehicleRequest"));
  const [vehicleRequestData, setVehicleRequestData] = useState({ ...data });
  const [vehicleRequests, setVehicleRequests] = useState([{}]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [show, setShow] = useState(false);
  var commaNumber = require("comma-number");
  const {
    mainClass,
    transporterList,
    sidebar,
    setTransporterList,
    customerData,
    setApiCalling,
    orderType,
    auctionDetails,
  } = useContext(AppContext);

  useEffect(() => {
    getVehicleList();
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const edit = (transporter) => {
    localStorage.setItem("transporter_id", transporter.user_id);
    navigate("/AddTransporter");
  };
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });

  const getVehicleList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      vehicleRequestData?.trip_status === "requested"
        ? ApiConfig.TRANSPORTER_LIST
        : (vehicleRequestData?.vehicle_request_status === "reconfirm" ? ApiConfig.TRANSPORTERLIST_FOR_RESSIGN : null),
      JSON.stringify({ ...customerData, user_role: vehicleRequestData?.user_role, load_id: vehicleRequestData?.trip_id, vehicle_request_id: vehicleRequestData?.vehicle_request_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setTransporterList(
            res.json.transporter_list.filter(
              (trasporter) => trasporter.user_status !== "blocked"
            )
          );
          
          setOriginalData(
            res.json.transporter_list.filter(
              (trasporter) => trasporter.user_status !== "blocked"
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };
  const blockTransporter = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        swal(res.json.message).then(() => {
          setLoading(true);
          getVehicleList();
        });
      }
    });
  };
  const approoveRequest = () => {
    if (selectedIds.length) {
      setApiCalling(true);
      postWithAuthCallWithErrorResponse(
        ApiConfig.APPROOVE_VEHICLE_REQUEST,
        JSON.stringify({
          ...customerData,
          load_id: vehicleRequestData?.trip_id,
          transporter_ids: selectedIds,
          // vehicle_availability_date:
          //   vehicleRequestData.vehicle_availability_date,
          // requirements: requirements
          order_type: orderType,
          auction_details: auctionDetails,
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false);
          if (res.json.result) {
            localStorage.removeItem("request");
            swal(res.json.message);
            localStorage.removeItem("currentVehicleRequest");
            setVehicleRequests(res.json.vehicleRequests);

            navigate("/DirectOrderHeader");
          } else {
            swal(res.json.message);
          }
        })
        .catch((err) => {
          setApiCalling(false);
          console.log(err);
        });
    } else {
      swal("Please select delivery transporters");
    }
  };
  useEffect(() => {
    $(".dataList").DataTable().destroy();
    let data = originalData.filter((data) => {
      if (selected === "All") return data;
      else if (selected === "active") return data.user_status === "active";
      else if (selected === "inactive") return data.user_status === "blocked";
      else if (selected === "yes") return data.contract === "yes";
      else if (selected === "no") return data.contract === "no";
      else return data;
    });
    $(".dataList").DataTable().destroy();
    setTransporterList(data);
  }, [selected]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = transporterList.map((checkbox) => checkbox.user_id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const checkboxId = Number(event.target.id);
    if (event.target.checked) {
      setSelectedIds((prevIds) => [...prevIds, checkboxId]);
    } else {
      setSelectedIds((prevIds) => prevIds.filter((id) => id !== checkboxId));
    }
  };

  const quintalValue =
    Number(
      vehicleRequestData?.trip_status === "reassign" || 
      vehicleRequestData?.trip_status === "partially_confirmed" || 
      vehicleRequestData?.trip_status === "partially_initiated" ||
      vehicleRequestData?.trip_status === "partially_completed" ||
      vehicleRequestData?.trip_status === "match_made_partially"
        ? vehicleRequestData?.pending_quantity
        : vehicleRequestData?.quantity
    ) * 10;


  return (
    <main className={`SelectTransporter-main  ${mainClass}`} id="cxMain">
      <div
        style={{ overflow: "auto", padding: "30px 0", marginBottom: "150px" }}
      >
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            {/* <Filters onSelect={setSelected} /> */}
            <p>
              Required Vehicle Capacity :
              <span className="text-danger">
                {" "}
                {vehicleRequestData?.trip_status === "reassign" || 
                vehicleRequestData?.trip_status === "partially_confirmed" || 
                vehicleRequestData?.trip_status === "partially_initiated" ||
                vehicleRequestData?.trip_status === "partially_completed" ||
                vehicleRequestData?.trip_status === "match_made_partially"
                  ? vehicleRequestData?.pending_quantity
                  : vehicleRequestData?.quantity}{" "}
                {vehicleRequestData?.unit}
                {vehicleRequestData?.unit === "ton" &&
                  `(${commaNumber(
                    Math.round(quintalValue).toFixed(2)
                  )} Quintals)`}
              </span>
            </p>

            <p>
              Type Of Cargo :{" "}
              <span className="text-danger">
                {vehicleRequestData?.cargo_type}
              </span>
            </p>
            <table
              className="table-striped dataList"
              id="transporterList"
              style={{ minWidth: "1200px" }}
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="select-all"
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>S.No</th>
                  <th>Company Type</th>
                  <th>Company Name</th>
                  <th>Contact Person</th>
                  {/* <th>Fleet Size</th> */}
                  <th>Vehicles Available</th>
                  <th>Total Vehicles Capacity(Quintals)</th>
                  <th>City</th>
                  <th>Country</th>
                  {/* <th>Contract</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>

                {vehicleRequestData?.trip_is_delivery === true ? (transporterList.filter(e=> (e?.trip_is_delivery === true || e.user_role === 'delivery')).map((transporter, index) => {
                  return (
                    <tr className="Rowbgcolor " key={transporter.user_id}>
                      <div className="form-check pt-2 ms-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={transporter.user_id}
                          onChange={handleCheckboxChange}
                          checked={selectedIds.includes(transporter.user_id)}
                        />
                      </div>
                      <td>{index + 1}</td>
                      <td>{transporter.company_type}</td>
                      <td>{transporter.company_name}</td>
                      <td>
                        {transporter.contact_person} (
                        {transporter.contact_person_phone})
                      </td>
                      {/* <td>{transporter.user_fleet_size}</td> */}
                      <td>{transporter.vehicles}</td>
                      <td>{transporter.total_vehicle_capacity}</td>
                      <td>{transporter.user_city}</td>
                      <td>{transporter.user_country}</td>
                      {/* <td>{transporter.contract}</td> */}
                      <td>{transporter.user_status}</td>
                      <td>
                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className="ms-5 ps-5"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link
                                to={
                                  "/TransporterDetails/" + transporter.user_id
                                }
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View Details
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={handleShow}
                                >
                                  View Vehicles
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/ContractTransporter/" + transporter.user_id
                                }
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  Contract
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  Call
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={() =>
                                    blockTransporter(transporter.user_id)
                                  }
                                >
                                  Block
                                </button>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })): transporterList.filter(e=> (e?.trip_is_delivery === false || e.user_role === 'transporter')).map((transporter, index) => {
                  return (
                    <tr className="Rowbgcolor " key={transporter.user_id}>
                      <div className="form-check pt-2 ms-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={transporter.user_id}
                          onChange={handleCheckboxChange}
                          checked={selectedIds.includes(transporter.user_id)}
                        />
                      </div>
                      <td>{index + 1}</td>
                      <td>{transporter.company_type}</td>
                      <td>{transporter.company_name}</td>
                      <td>
                        {transporter.contact_person} (
                        {transporter.contact_person_phone})
                      </td>
                      {/* <td>{transporter.user_fleet_size}</td> */}
                      <td>{transporter.vehicles}</td>
                      <td>{transporter.total_vehicle_capacity}</td>
                      <td>{transporter.user_city}</td>
                      <td>{transporter.user_country}</td>
                      {/* <td>{transporter.contract}</td> */}
                      <td>{transporter.user_status}</td>
                      <td>
                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className="ms-5 ps-5"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link
                                to={
                                  "/TransporterDetails/" + transporter.user_id
                                }
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View Details
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={handleShow}
                                >
                                  View Vehicles
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/ContractTransporter/" + transporter.user_id
                                }
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  Contract
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  Call
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={() =>
                                    blockTransporter(transporter.user_id)
                                  }
                                >
                                  Block
                                </button>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div
        className="fix-offer-vehicles"
        style={{ width: !sidebar ? "95%" : "80%" }}
      >
        <div
          className="d-flex justify-content-between p-2 mb-4"
          style={{ minWidth: "950px" }}
        >
          <div className="">
            <label htmlFor="">Reference Number</label>
            <p className="data">{vehicleRequestData?.load_reference_no}</p>
          </div>
          {/* <div className=" ">
              <label htmlFor="">Goods Name</label>
              <p className="data">{vehicleRequestData && vehicleRequestData.goods_name}</p>
            </div> */}
          <div className=" ">
            <label htmlFor="">Company Name</label>
            <p className="data">{vehicleRequestData?.company_name}</p>
          </div>
          <div className="">
            <label htmlFor="">Cargo Type</label>
            <p className="data">{vehicleRequestData.cargo_type}</p>
          </div>
          <div className="">
            <label htmlFor="">Quantity</label>
            <p>
              <span className="data m-0">
                {vehicleRequestData?.pending_quantity
                  ? vehicleRequestData?.pending_quantity
                  : vehicleRequestData?.quantity}
              </span>{" "}
              {/* <Link to="#" className="ms-2 ViewSpecifications-link">
                View Specifications
              </Link> */}
            </p>
          </div>
          <div className="">
            <label htmlFor="">Delivery Type</label>
            <p className="data">{vehicleRequestData?.delivery_type}</p>
          </div>
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="">
              <button type="cancel" className="cancel-btn">
                Cancel
              </button>
            </a>
            {/* <Link to="/OfferVehicleListing"> */}
            <button
              type="submit"
              className="continue-btn"
              onClick={() => {
                approoveRequest();
              }}
            >
              Assign
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box popup-specification"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <h3 className=" card-heading mt-2">Truck Specifications </h3>
        </div>
        <div
          className="btn-popup-modal text-center"
          style={{ overflow: "auto" }}
        >
          <table
            className="example table-striped"
            style={{ minWidth: "400px" }}
          >
            <thead>
              <tr>
                <td>
                  <label htmlFor="">Trucks</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Type</label>
                </td>
                <td>
                  <label htmlFor="">Capacity in Quintals</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {specifications &&
                specifications.length &&
                specifications.map((spec, index) => {
                  return (
                    <tr className="t-row " key={"spec" + index}>
                      <td>
                        <p className="data m-0 ps-2">{index + 1}</p>
                      </td>
                      <td>
                        <p className="data m-0">{spec.cargo_type}</p>
                      </td>
                      <td>
                        <p className="data m-0">{spec.capacity}</p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Modal>
    </main>
  );
};

export default SelectTransporter;
