import React, { useContext, useEffect, useState } from "react";
import ic_edit from "../../assets/images/ic_edit (2).svg";

import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import {
  postWithAuthCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

const ShipperApproval = () => {
  const navigate = useNavigate();

  const { customerData } = useContext(AppContext);
  const [shipperPendingList, setShipperPendingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const getShipperList = () => {
    var uri = ApiConfig.SHIPPER_PENDING_LIST;
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setShipperPendingList(res.json.shipper_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const shipperApprove = (id) => {
    PostCallWithErrorResponse(ApiConfig.APPROVE_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getShipperList();
          navigate("/ShippersLists/");
          swal(res.json.message);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const shipperReject = (id) => {
    PostCallWithErrorResponse(ApiConfig.REJECT_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getShipperList();
          swal(res.json.message);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShipperList();
  }, []);

  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  <table className="dataList" style={{ minWidth: "900px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Company Name</label>
                        </th>
                        <th>
                          <label>Contact Phone No</label>
                        </th>
                        <th>
                          <label>Email</label>
                        </th>

                        <th>
                          <label>Region</label>
                        </th>
                        <th>
                          <label>Accept</label>
                        </th>
                        <th>
                          <label>Reject</label>
                        </th>
                        {/* <th
                          className=" text-end "
                          style={{ paddingRight: "50px" }}
                        >
                          <label>Action</label>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {shipperPendingList ? (
                        shipperPendingList &&
                        shipperPendingList.map((shipperList, index) => {
                          return (
                            <tr
                              className="Rowbgcolor"
                              key={"shipperList" + index}
                            >
                              <td>
                                <label>{index + 1}</label>
                              </td>
                              <td>
                                <Link
                                  className="text-decoration-none"
                                  to={"/ShipperDetails/" + shipperList.user_id}
                                  state={shipperList}
                                >
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                      color: "#385f84",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {shipperList.company_name}
                                  </label>
                                </Link>
                              </td>
                              <td>
                                <label>{shipperList.user_mobile}</label>
                              </td>
                              <td>
                                <label>{shipperList.user_email}</label>
                              </td>

                              <td>
                                <label>{shipperList.user_region}</label>
                              </td>
                              {/* <td>
                                <label>{shipperList.contract}</label>
                              </td> */}
                              <td>
                              <Link
                                      to="#"
                                      onClick={() =>
                                        shipperApprove(shipperList.user_id)
                                      }
                                      className="regiBTN"
                                      id="accept"
                                    >
                                      Accept
                                      {/* <img src={cross_green} alt="" /> */}
                                    </Link>
                              </td>
                              <td>
                              <Link
                                    to="#"
                                    onClick={() =>
                                      shipperReject(shipperList.user_id)
                                    }
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    {/* <img src={tick_green} alt="" /> */}
                                    Reject
                                  </Link>
                              </td>
                              {/* <td className="text-end">
                                <label>
                                  {shipperList.contract === "yes" && (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        shipperApprove(shipperList.user_id)
                                      }
                                      className="regiBTN"
                                      id="accept"
                                    >
                                      Accept
                                      
                                    </Link>
                                  )}
                                  {shipperList.contract === "no" && (
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        handleShow(
                                          setSelectedId(shipperList?.user_id)
                                        );
                                      }}
                                      className="regiBTN"
                                      id="accept"
                                    >
                                      Accept
                                      
                                    </Link>
                                  )}
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      shipperReject(shipperList.user_id)
                                    }
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    s
                                    Reject
                                  </Link>
                                </label>
                              </td> */}
                              <Modal
                                show={show}
                                onHide={handleClose}
                                animation={true}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className="modal-content-box vehicle-requests-main-modal "
                              >
                                <Modal.Header
                                  onClick={() => {
                                    setModal(!modal);
                                  }}
                                  closeButton
                                ></Modal.Header>

                                <div>
                                  <p className="text-center popup-heading mt-4">
                                    Please Upload Contract?
                                  </p>
                                </div>
                                <div className="btn-popup-modal text-center mt-4">
                                  <button
                                    className="popup-reject-btn m-1 "
                                    onHide={handleClose}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="popup-approve-btn m-1 "
                                    onClick={() =>
                                      navigate("/AddShipper/" + selectedId)
                                    }
                                    onHide={handleClose}
                                  >
                                    Upload Contract
                                  </button>
                                </div>
                              </Modal>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="text-danger"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Load Available
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default ShipperApproval;
